.wideImgHeader {
    width: 100%;
    margin-left: auto;
    margin-right: auto
}

.headerTitleContent {
    font-size: 2.7em;
    font-weight: normal;
    top: -3em;
    position: relative;
    background: #ea7399bf;
}

@media (min-width: 1024px) {
    .headerTitleContent{
        top: -7em;
    }
}