.tinyImgHeader {
    width: 30%;
    height: 120px;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 1024px) {
    .tinyImgHeader {
        width: 13%;
    }
}